import { Pinia, Store } from '@/store/pinia-class-component';
import {
  fetchImages,
  fetchImagesConfirmation,
} from '@/api/pictures/PicturesApi';
import { IndexedDBRow, ObjectType } from '@/utils/indexedDb/types/IndexedDBRow';
import { PictureConfirmation } from '@/api/pictures/PictureConfirmation';

export function useImagesStore() {
  return new ImageStore();
}

@Store
export class ImageStore extends Pinia {
  private readonly _images: PictureConfirmation[];
  private _imagesToBeDeleted: IndexedDBRow[];

  constructor() {
    super();
    this._images = [];
    this._imagesToBeDeleted = [];
  }

  get images(): PictureConfirmation[] {
    return this._images;
  }

  get imagesToBeDeleted(): IndexedDBRow[] {
    return this._imagesToBeDeleted;
  }

  objectHasImages(objectId: string) {
    return this._images.find((img) => {
      return img.objectId === objectId;
    });
  }

  addImagesToBeDeleted(images: IndexedDBRow[]) {
    images.forEach((image) => this._imagesToBeDeleted.push(image));
  }

  resetImagesToBeDeleted() {
    this._imagesToBeDeleted = [];
  }

  addObjectToList(objectId: string, objectType: ObjectType) {
    if (!this.objectHasImages(objectId)) {
      this._images.push(new PictureConfirmation(objectId, objectType));
    }
  }

  async getImagesConfirmation(businessEntityId: string) {
    const picturesConfirmation = await fetchImagesConfirmation(
      businessEntityId
    );
    picturesConfirmation.forEach(({ objectId, objectType }) => {
      if (!this.objectHasImages(objectId)) {
        this.addObjectToList(objectId, objectType);
      }
    });
  }

  getImages(
    businessEntityId: string,
    objectType: ObjectType,
    objectId: string
  ) {
    return fetchImages(businessEntityId, objectType, objectId);
  }
}
