/**
 * Wrapper Class to interact with IndexedDB indexedDb.
 * All public methods are promise based
 */
import { generateUniqueId } from '@/helpers/uuidUtils.js';
import { IndexedDBRow } from '@/utils/indexedDb/types/IndexedDBRow';

const objectStoreName = 'images';

/**
 * Creates and returns a new IndexedDB indexedDb.
 * @returns Promise<IDBDatabase>
 *
 */
export async function getIndexedDB(): Promise<IDBDatabase> {
  let databaseReq: IDBOpenDBRequest;

  return new Promise((resolve) => {
    databaseReq = window.indexedDB.open('bxapp', 2);

    databaseReq.onerror = (e) => {
      console.error('Error opening IndexedDb', e);
      resolve(databaseReq.result);
    };

    databaseReq.onsuccess = () => {
      console.debug('IndexedDb created Successfully');
      resolve(databaseReq.result);
    };

    databaseReq.onupgradeneeded = (e: IDBVersionChangeEvent) => {
      const db: IDBDatabase = e.target.result;

      if (!db.objectStoreNames.contains(objectStoreName)) {
        const imagesStore = db.createObjectStore(objectStoreName, {
          keyPath: 'id',
        });
        imagesStore.createIndex('businessEntityId', 'businessEntityId', {
          unique: false,
        });
        imagesStore.createIndex('objectId', 'objectId', { unique: false });
      }

      // If requires migration
      if (db.objectStoreNames.contains('radiators')) {
        console.info('Old database found, deleting it...');
        db.deleteObjectStore('radiators');
      }
    };
  });
}

/**
 * Add/Update an Image in the indexedDb.
 * @param {IndexedDBRow} entry
 * @returns Promise<void>
 */
export async function addEntityToDb(entry: IndexedDBRow): Promise<Event> {
  const database: IDBDatabase = await getIndexedDB();
  const transaction: IDBTransaction = getTransaction(database);
  const store: IDBObjectStore = getStore(transaction);

  entry.id = generateUniqueId();
  const newEntry = JSON.parse(JSON.stringify(entry));

  return new Promise((resolve) => {
    transaction.oncomplete = (e: Event) => {
      resolve(e);
    };

    store.add(newEntry);
  });
}

/**
 * Get all entities grouped by id index from the indexedDb.
 * @param {string} keyIndex
 * @param {string} indexType
 *
 * @returns Promise<Array<IndexedDBRow>>
 */
export async function getEntitiesFromDBByIndex(
  keyIndex: string,
  indexType = 'id'
): Promise<Array<IndexedDBRow>> {
  const database: IDBDatabase = await getIndexedDB();
  const transaction: IDBTransaction = getTransaction(database);
  const store: IDBObjectStore = getStore(transaction);

  return new Promise((resolve) => {
    try {
      const index = store.index(indexType);
      const request = index.getAll(keyIndex);
      request.onsuccess = () => {
        resolve(request.result);
      };
    } catch (error) {
      resolve([]);
    }
  });
}

/**
 * Delete a single entity by index (ID) from the indexedDb.
 * @param {string} index
 *
 * @param indexType
 * @returns Promise<void>
 */
export async function deleteEntityByIdIndex(
  index: string,
  indexType = 'id'
): Promise<string> {
  const database: IDBDatabase = await getIndexedDB();
  const transaction: IDBTransaction = getTransaction(database);
  const store: IDBObjectStore = getStore(transaction);

  return new Promise((resolve, reject) => {
    transaction.oncomplete = () => {
      resolve('Deleted');
    };

    try {
      const entityDestroy = store.get(index);
      entityDestroy.onsuccess = () => {
        const row = entityDestroy.result;

        if (row) {
          store.delete(index);
        }
      };
    } catch (error) {
      console.debug('nothing to delete, moving on...');
    }
  });
}

/**
 * Purges the indexedDb.
 *
 * @returns Promise<string>
 */
export async function clearObjectStore(): Promise<string> {
  const database: IDBDatabase = await getIndexedDB();
  const transaction: IDBTransaction = getTransaction(database);
  const store: IDBObjectStore = getStore(transaction);

  return new Promise((resolve) => {
    transaction.oncomplete = () => {
      resolve('Store Cleared');
    };
    store.clear();
  });
}

function getStore(transaction: IDBTransaction): IDBObjectStore {
  return transaction.objectStore(objectStoreName);
}

function getTransaction(database: IDBDatabase): IDBTransaction {
  return database.transaction([objectStoreName], 'readwrite');
}
