import axios from 'axios';
import { Radiator } from '@/models/radiators/Radiator';
import { apiEndpoint } from '@/enums/generic';
import { RadiatorIdentificationFactory } from '@/api/radiator/RadiatorIdentificationFactory';
import { ImageMapper } from '@/api/radiator/ImageMapper';

const radiatorRatingsUrl = apiEndpoint.radiatorRatingsUrl;

export const getRadiatorRatings = async function (radiator: Radiator) {
  return axios({
    method: 'POST',
    url: `${radiatorRatingsUrl}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: RadiatorIdentificationFactory.by(radiator),
    params: { limit: 10 },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

export const fetchRadiatorIdentificationPictures = async function (
  identificationPrefix: string
): Promise<Array<Image>> {
  const response = await axios({
    method: 'GET',
    url: `${radiatorRatingsUrl}/pictures/${identificationPrefix}`,
  }).catch(function (error) {
    console.error(error.response);
  });
  return response?.data?.map((image: any) => ImageMapper.apiToDTO(image));
};
