import axios from 'axios';
import { ObjectImageMapper } from '@/api/radiator/ObjectImageMapper';
import { apiEndpoint } from '@/enums/generic';
import { ImageMapper } from '@/api/radiator/ImageMapper';
import { IndexedDBRow, ObjectType } from '@/utils/indexedDb/types/IndexedDBRow';
import { PictureConfirmation } from '@/api/pictures/PictureConfirmation';

const businessEntitiesUrl = apiEndpoint.businessEntitiesUrl;

export const putImage = async function (dbRow: IndexedDBRow) {
  const deviceImageDTO: Image = ObjectImageMapper.toDTO(dbRow);

  return axios({
    method: 'PUT',
    url: `${businessEntitiesUrl}/${dbRow.businessEntityId}/pictures/${dbRow.objectType}/${dbRow.objectId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: deviceImageDTO,
  }).catch(function (error) {
    return error.response;
  });
};

export const fetchImagesConfirmation = async function (
  businessEntityId: string
): Promise<Array<PictureConfirmation>> {
  try {
    const { data } = await axios.get<PictureConfirmation[]>(
      `${businessEntitiesUrl}/${businessEntityId}/pictures/pictures-confirmation`
    );
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchImages = async function (
  businessEntityId: string,
  objectType: ObjectType,
  objectId: string
): Promise<Image[]> {
  const response = await axios({
    method: 'GET',
    url: `${businessEntitiesUrl}/${businessEntityId}/pictures/${objectType}/${objectId}`,
  }).catch((error) => {
    console.error(error.response);
  });
  if (!response || (response.status && response.status >= 400)) {
    return [];
  } else {
    const images: Image[] = [];
    response.data.forEach((image: Image) => {
      images.push(ImageMapper.apiToDTO(image));
    });
    return images;
  }
};

export const deleteImages = async function (
  businessEntityId: string,
  objectType: ObjectType,
  imagesToBeDeleted: Array<string>
) {
  const response = await axios({
    method: 'DELETE',
    url: `${businessEntitiesUrl}/${businessEntityId}/pictures/${objectType}`,
    data: imagesToBeDeleted,
  }).catch((error) => {
    console.error(error.response);
  });
};
