<template>
  <div class="mb-12 mt-24 md:mt-9">
    <div
      class="mb-6 flex flex-col justify-center align-middle md:mb-0 md:flex-row md:justify-between"
    >
      <div>
        <h1 class="mb-1 flex self-center md:self-auto">Gebäudeübersicht</h1>
        <div
          v-if="
            businessEntity?.billingUnitReference || businessEntity?.propertyId
          "
          class="mb-6 text-sm"
        >
          {{ businessEntity?.billingUnitReference }}
          <span
            v-if="
              businessEntity?.billingUnitReference && businessEntity?.propertyId
            "
          >
            /
          </span>
          {{ businessEntity?.propertyId }}
        </div>
      </div>
      <div class="flex self-center md:self-auto">
        <CloseOrderButton />
      </div>
    </div>

    <div class="relative flex flex-row">
      <BuildingCardCollection
        v-if="buildings.length > 0"
        :buildings="buildings"
      />
      <div
        class="m-2 flex h-16 w-[100px] shrink cursor-pointer flex-col justify-center rounded-xl border-dark-2 bg-light-1 py-5 text-center align-middle text-3xl text-primary-2 shadow hover:scale-[1.02] hover:bg-light-2 dark:border dark:bg-dark-1 hover:dark:bg-dark-2"
        data-testid="createBuildingButton"
        @click="addBuilding"
      >
        <em class="mdi mdi-plus"></em>
      </div>
    </div>

    <div
      v-if="buildings.length > 0"
      class="mt-10 flex flex-col gap-5"
      data-testid="floorTiles"
    >
      <div class="flex flex-row justify-end">
        <div
          :class="[
            'rounded-lg bg-light-2 px-2 py-1 hover:bg-primary-2 hover:text-white dark:bg-dark-2 hover:dark:bg-primary-1',
            { 'bg-primary-2 text-white dark:bg-primary-1': isSwapModeActive },
          ]"
          @click="toggleSwapMode"
        >
          <em class="mdi mdi-swap-horizontal text-3xl"></em>
        </div>
      </div>
      <div
        class="flex h-14 w-full flex-col justify-center rounded-xl border-dark-3 bg-light-1 text-center align-middle text-3xl text-primary-2 shadow hover:bg-light-2 dark:border dark:bg-dark-2 hover:dark:bg-dark-3"
        data-testid="addFloorOGButton"
        @click="addFloor('OG')"
      >
        <em class="mdi mdi-plus"></em>
      </div>

      <BuildingFloorLevel
        v-for="(roomGroupData, floorName) in buildingStruct"
        :key="floorName"
        data-testid="floorTile"
        :current-building="activeBuilding"
        :room-group-data="roomGroupData"
        :floor-name="floorName"
        :is-highest-floor-level="isHighestFloorLevel(floorName)"
      >
      </BuildingFloorLevel>
      <div
        class="flex h-14 w-full flex-col justify-center rounded-xl border-dark-3 bg-light-1 text-center align-middle text-3xl text-primary-2 shadow hover:bg-light-2 dark:border dark:bg-dark-2 hover:dark:bg-dark-3"
        data-testid="addFloorUGButton"
        @click="addFloor('UG')"
      >
        <em class="mdi mdi-plus"></em>
      </div>
    </div>
    <div
      v-else
      class="mx-1 mt-10 rounded-lg bg-light-1 px-5 py-5 dark:bg-dark-1"
    >
      Diese Gewerbeeinheit ist leer. <br />
      Bitte lege das erste Gebäude mithilfe der
      <em class="mdi mdi-plus text-primary-2 dark:text-primary-2" />
      Schaltfläche an.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Notification, Room, Roomgroup } from '@/models';
import { computed, onMounted, ref, watch, provide } from 'vue';
import BuildingFloorLevel from '@/components/BuildingOverview/BuildingFloorLevel.vue';
import { floorLevelList, floorLevelListMapping } from '@/enums/room';
import CloseOrderButton from '@/components/Header/CloseOrderButton.vue';
import useStores from '@/composables/useStores';
import { useBuildingCard } from '@/composables/useBuildingCard';
import BuildingCardCollection from '@/components/BuildingOverview/BuildingCardCollection.vue';
import { useBuildingStore } from '@/store/entities/buildingStore';

const {
  roomStore,
  entitiesStore,
  roomGroupStore,
  buildingStore,
  formStore,
  layoutStore,
  persistenceStore,
  businessEntityStore,
} = useStores();

const businessEntity = computed(() => {
  return businessEntityStore.businessEntity;
});

const activeBuilding = computed(() => {
  return buildingStore.getCurrentBuilding();
});

const { changeActiveBuilding, editBuilding, isActiveBuilding, addBuilding } =
  useBuildingCard();

const isSwapModeActive = ref(false);
provide('isSwapModeActive', isSwapModeActive);
provide('changeActiveBuilding', changeActiveBuilding);
provide('editBuilding', editBuilding);

onMounted(() => {
  const currentBuildingId = buildingStore.buildingId;
  if (!currentBuildingId) {
    const firstBuilding = buildingStore.buildings.values().next().value;
    if (!firstBuilding) return;
    buildingStore.setBuilding(firstBuilding);
    return;
  }
});

const buildings = computed(() => buildingStore.getBuildings());
const getRoomGroups = computed(() => {
  if (activeBuilding.value) {
    return roomGroupStore.getRoomGroupsByBuildingId(activeBuilding.value.id);
  }
  return [];
});
const toggleSwapMode = () => {
  isSwapModeActive.value = !isSwapModeActive.value;
};

const buildingStruct = computed(() => {
  if (!activeBuilding.value) {
    return [];
  }
  const roomGroups = getRoomGroups;
  const buildingStructArray: any = {};
  roomGroups.value.forEach((roomgroup) => {
    const firstRoom = roomStore.getFirstRoomOfRoomgroup(roomgroup.id);
    if (!firstRoom) {
      return;
    }
    const floorType = firstRoom.getFloor();
    if (!floorType) return;
    if (!buildingStructArray[floorType]) {
      buildingStructArray[floorType] = {};
      buildingStructArray[floorType]['roomgroups'] = [];
    }

    if (
      roomgroup.usageType === 'GENERAL' &&
      firstRoom.usageType === 'HALLWAY_AND_STAIRCASE'
    ) {
      buildingStructArray[floorType]['entrance'] = roomgroup;
    } else {
      buildingStructArray[floorType]['roomgroups'].push(roomgroup);
    }
    buildingStructArray[floorType]['roomgroups'].sort(function (
      a: any,
      b: any
    ) {
      return parseInt(a.ordinal) - parseInt(b.ordinal);
    });
  });
  useBuildingStore().setBuildingStruct(buildingStructArray);
  return sortFloors(buildingStructArray);
});

watch(
  () => buildings.value.length,
  (newBuildingListLength: any) => {
    const lastBuilding = buildings.value[newBuildingListLength - 1];
    if (lastBuilding) {
      changeActiveBuilding(lastBuilding);
      isActiveBuilding(lastBuilding);
      return true;
    }
  }
);

const addFloor = (type: string) => {
  const roomgroup = new Roomgroup();
  roomgroup.buildingId = activeBuilding.value?.id;
  roomgroup.usageType = 'GENERAL';
  roomgroup.ordinal = 0;
  const room = new Room();
  room.usageType = 'HALLWAY_AND_STAIRCASE';
  room.roomGroupId = roomgroup.id;
  room.ordinal = 0;
  const floors = Object.keys(buildingStruct.value);
  let level = 0;

  if (floors.filter((b) => b.includes(type)).length > 0) {
    level = floors.filter((b) => b.includes(type)).length;
  }
  const currentFloorLevelType = floorLevelList.find((f: any) => f.name === type)
    ?.value as keyof typeof floorLevelListMapping | undefined;
  if (currentFloorLevelType) {
    room.floorLevel = {
      type: currentFloorLevelType,
      level: level + 1,
    };
  }
  const floorLevel = level !== 0 ? `${type}-${level}` : 'EG';
  const firstRoom = roomStore.getFirstRoomOfRoomgroup(
    buildingStruct.value[floorLevel].entrance.id
  );
  room.parentRoomId = firstRoom?.id;

  entitiesStore.saveEntity(roomgroup);

  const options = new Notification(
    `Die Etage ${type}-${room.floorLevel.level} wurde hinzugefügt.`,
    'Etage wurde hinzugefügt!',
    'success',
    3000
  );
  entitiesStore.saveEntity(room, options);
};
const sortFloors = (obj: any) => {
  const newObj: any = {};
  const revObj: any = Object.keys(obj).sort((e1, e2) => {
    const el1: any = { type: e1.split('-')[0], floor: e1.split('-')[1] };
    const el2: any = { type: e2.split('-')[0], floor: e2.split('-')[1] };

    if (el1.type === el2.type && el1.type === 'OG') {
      return el2.floor - el1.floor;
    }

    if (el1.type === el2.type && el1.type === 'UG') {
      return el1.floor - el2.floor;
    }

    if (el1.type === 'EG' && el2.type === 'UG') {
      return -1;
    }

    if (el1.type === 'OG') {
      return -1;
    }
    return 1;
  });

  revObj.forEach(function (i: string) {
    newObj[i] = obj[i];
  });

  return newObj;
};
const isHighestFloorLevel = (floorName: any) => {
  const floorLevelKeys = Object.keys(buildingStruct.value);
  const floorLevels = floorLevelKeys
    .filter((floorLevel) => floorLevel.includes(floorName.split('-')[0]))
    .map((floorLevel) => Number(floorLevel.split('-')[1]));
  const highestFloorLevel = Math.max(...floorLevels);
  return floorName.includes(highestFloorLevel);
};
</script>
