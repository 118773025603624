export class ImageMapper {
  /**
   * Convert API response to imageDTO
   * for Frontend to consume
   *
   * @returns Image
   *
   * @param image
   */
  public static apiToDTO(image: Image): Image {
    return {
      id: image.id,
      image: `data:${image.mimetype};base64,${image.image}`,
      mimetype: image.mimetype,
    };
  }
}
