import axios from 'axios';
import { apiEndpoint } from '@/enums/generic';
import { Order } from '@/models/Order';
import { OrderStatus } from '@/models/types/OrderStatus';
import { plainToInstance } from 'class-transformer';

const ordersUrl = apiEndpoint.assignedOrdersUrl;
const statusOrderUrl = apiEndpoint.statusOrderUrl;

export const fetchAllAssignedOrders = async function (): Promise<Array<Order>> {
  const { data } = await axios.get<Order[]>(`${ordersUrl}`);
  return data.map((order) => {
    return plainToInstance(Order, order);
  });
};

export const saveOrderStatus = async function (
  orderId: string,
  statusValue: OrderStatus
): Promise<void> {
  const status = { status: statusValue };
  await axios({
    method: 'PUT',
    url: `${statusOrderUrl}/${orderId}/status`,
    data: status,
  });
};

export const putOrderProgress = async function (selectedOrder: Order) {
  const progress = { changes: selectedOrder.changes };
  await axios({
    method: 'PUT',
    url: `${statusOrderUrl}/${selectedOrder.id}/progress`,
    data: progress,
  });
};
