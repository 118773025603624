import { ObjectType } from '@/utils/indexedDb/types/IndexedDBRow';

export class PictureConfirmation {
  public objectId: string;
  public objectType: ObjectType;
  constructor(objectId: string, objectType: ObjectType) {
    this.objectId = objectId;
    this.objectType = objectType;
  }
}
