import { IndexedDBRow } from '@/utils/indexedDb/types/IndexedDBRow';

/**
 * Provides functionality to map DTOs to domain objects and vice versa.
 * Mapper for radiator image.
 */
export class ObjectImageMapper {
  /**
   * Returns a radiator image DTO for the API to consume
   *
   * @param objectImage - IndexedDBRow
   * @returns Image
   *
   */
  public static toDTO(objectImage: IndexedDBRow): Image {
    const mimeType = objectImage.image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
    const image = objectImage.image.split(',')[1];
    if (!objectImage.id) {
      throw Error('No image id available.');
    }
    return {
      id: objectImage.id,
      image: image,
      mimetype: mimeType ? mimeType[0] : '',
    };
  }
}
