import { useFormStore } from '@/store/form/formStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useRoomStore } from '@/store/entities/roomStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useUserStore } from '@/store/user/userStore';
import { useOrderStore } from '@/store/order/orderStore';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useBusinessEntityStore } from '@/store/entities/businessEntityStore';
import { usePlantStore } from '@/store/entities/plantStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useImagesStore } from '@/store/images/imageStore';

export default function useStores() {
  return {
    buildingStore: useBuildingStore(),
    entitiesStore: useEntitiesStore(),
    businessEntityStore: useBusinessEntityStore(),
    deviceStore: useDeviceStore(),
    persistenceStore: usePersistenceStore(),
    formStore: useFormStore(),
    layoutStore: useLayoutStore(),
    roomGroupStore: useRoomGroupStore(),
    roomStore: useRoomStore(),
    notificationStore: useNotificationStore(),
    funkcheckStore: useFunkcheckStore(),
    imagesStore: useImagesStore(),
    userStore: useUserStore(),
    orderStore: useOrderStore(),
    radiatorStore: useRadiatorStore(),
    plantStore: usePlantStore(),
    installationPointStore: useInstallationPointStore(),
  };
}
